<template>
  <div class="page">
    <Viewheader theme="light" :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <img class="banner-bg" :src="bannerImg" />
        <div class="banner-imgs">
          <img class="banner1" src="@/assets/img/imgYellow/banner-1.png" />
          <img class="banner2" src="@/assets/img/imgYellow/banner-2.png" />
          <img class="banner3" src="@/assets/img/imgYellow/banner-3.png" />
          <img class="banner4" src="@/assets/img/imgYellow/banner-4.png" />
          <img class="banner5" src="@/assets/img/imgYellow/banner-5.png" />
        </div>
      </div>
    </BannerHead>

    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo class="core-function_main" :list="spanList"></ColumuTwo>
      <div class="core-custom">
        <img
          class="core-custon_person"
          src="@/assets/img/imgYellow/core-1.png"
          alt=""
        />
        <div class="core-custon_text">
          <p>支持自定义“色情标准”</p>
          <span
            >在色情图识别的基础上，支撑不同场景/渠道自定<br />义“色情标准。</span
          >
        </div>
        <div class="core-custom_diy">
          <img class="" src="@/assets/img/imgYellow/core-2.png" />
        </div>
        <div class="core-custom_IM">
          <img class="" src="@/assets/img/imgYellow/core-3.png" />
          <img class="" src="@/assets/img/imgYellow/core-4.png" />
          <img class="" src="@/assets/img/imgYellow/core-5.png" />
        </div>
      </div>
    </div>

    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" :desc="ability.desc" />
        <div class="operationWidth similar-main-cont">
          <div class="operationBackgroundColor">
            <!-- <div
              class="operationLeft"
              :class="{ prohibitClicking: prohibitClicking == true }"
            >
              <div
                class="operationLeftMain"
                v-for="(item, index) in exampleDiagram"
                :key="index"
                @click="selectPicture(index, item.img)"
                :class="{ imgActive: imgCurrentActive === index }"
              >
                <img :src="item.img" />
              </div>
            </div> -->
            <ImgUpload
              class="sc-img-upload"
              @success="getResultImg"
              @select="selectPicture"
              @change="handleChange"
              :modelIndex.sync="imgCurrentActive"
              :imgList="exampleDiagram"
              :action="action"
              :directionRow1280="true"
              accept="image/jpeg,image/jpg,image/png"
              uploadText="选择一张"
            />
            <div class="operationCenter">
              <!-- 成功图片 -->
              <div v-if="successImg" class="successImgProduct">
                <img :src="this.img_url" />
              </div>
              <!-- 报错图片 -->
              <div class="errMain" v-if="errImg">
                <div class="errImg">
                  <img src="../../assets/img/err.png" />
                </div>
                <div class="errText">
                  {{ errText }}
                </div>
              </div>

              <!-- 加载特效 -->
              <div class="loadSpecialEffects" v-if="loadImg"></div>

              <!-- 扫描脚框 -->
              <div class="top" v-if="loadImg"></div>
              <div class="bottom" v-if="loadImg"></div>
            </div>
            <div class="operationYellowRight">
              <div class="recognitionResult">
                识别结果：<span>{{
                  result === 'black' ? '黄' : result === 'white' ? '非黄' : ''
                }}</span>
              </div>
              <div class="confidenceLevel">置信度：{{ conf }}</div>
            </div>
          </div>
          <div class="actionText">
            <div class="detectionText">
              <input
                type="text"
                placeholder="粘贴网络图片URL"
                v-model="urlAdress"
              />
              <button
                class="detectionButton"
                @click="pornographicRecognition(1)"
                :disabled="this.urlAdress == ''"
              >
                检测
              </button>
            </div>
            <!-- <div class="localUpload">
              上传文件
              <el-upload
                :action="action"
                accept="image/jpeg,image/jpg,image/png"
                :show-file-list="false"
                class="uploadFileType"
                list-type="picture-card"
                :on-success="getResultImg"
                :headers="headers"
                :on-change="handleChange"
              >
              </el-upload>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 差异优势 -->
    <div class="different-advantage-wrap">
      <div class="different-advantage">
        <CommonTitle title="差异优势" theme="light" />
        <ColumuTwo
          class="different-advantage_main diff-top"
          theme="light"
          :list="diffList[0]"
          :img="diffImg"
        >
          <template #img>
            <div class="diff-list">
              <div class="diff-item3">
                <img src="@/assets/img/imgYellow/diff-3.png" />
              </div>
              <div class="diff-item4">
                <img src="@/assets/img/imgYellow/diff-4.png" />
              </div>
            </div>
          </template>
        </ColumuTwo>
        <ColumuTwo
          class="different-advantage_main diff-bottom"
          theme="light"
          :list="diffList[1]"
          :img="diffImg"
          :reverse="true"
        >
          <template #img>
            <div class="diff-list">
              <div class="diff-item1">
                <img src="@/assets/img/imgYellow/diff-1.png" />
              </div>
              <div class="diff-item2">
                <img src="@/assets/img/imgYellow/diff-2.png" />
              </div>
            </div>
          </template>
        </ColumuTwo>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene">
      <CommonTitle title="应用场景" />
      <ColumuTwo
        class="application-scene_main"
        :img="appImg"
        :list="appliList[0]"
      >
      </ColumuTwo>
      <div class="application-scene_list">
        <img src="@/assets/img/imgYellow/app-2.png" />
        <img src="@/assets/img/imgYellow/app-3.png" />
        <img src="@/assets/img/imgYellow/app-4.png" />
      </div>
      <ColumuTwo
        class="application-scene_main application-scene_main_bottom"
        :list="appliList[1]"
        :reverse="true"
      >
        <div slot="img">
          <div class="application-scene_img"></div>
        </div>
      </ColumuTwo>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';

import hexin from '@/assets/img/gongneng/seqing/hexin.jpg';
import cha9 from '@/assets/img/gongneng/seqing/chayi.jpg';
import googleLogo from '@/assets/img/imgSimilar/google.png';
// 上传图片列表组件
import ImgUpload from '@/components/secondPage/imgUpload.vue';

// 公用组件
import '../../assets/css/public.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/commonModule.css';
import { multiSensitive } from '@/api/aidata.js';
import { apiUri } from '../../api/txt';

// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '@/components/foot/footer.vue';
export default {
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    Viewheader,
    Footering,
    ImgUpload,
  },
  data() {
    return {
      bannerImg: require('@/assets/img/imgYellow/banner-bg.png'),
      coreImg: hexin,
      bigTitle_1: '<span style="color:#fff;">色情商品识别</span>',
      smallTitle_1:
        '<span style="color:#fff;">检测商品图片中的“色情商品图”并给出相应的分值</span>',

      // 核心功能
      spanList: [
        {
          title: '色情图识别',
          desc: '对商品图、评论图、IM等等场景下的图片，进行色情商品识别并输出置信度。',
        },
        // {
        //   title: '支持自定义“色情标准',
        //   desc: '在色情图识别的基础上，支撑不同场景/渠道自定义“色情标准”。',
        // },
      ],

      //   差异优势
      diffImg: cha9,
      diffList: [
        [
          {
            title: '符合大部分投放平台标准',
            desc: '区别于通用领域“色情定义”，该能力的“色情商品标准”参照了google、FB等投放平台，以及亚马逊、速卖通等平台对于“色情”的定义，符合大部分跨境平台标准。',
          },
        ],
        [
          {
            title: '验证准确率高',
            desc: '利用大量跨境电商数据训练模型，色情、非色情商品分类准确率95%+。',
          },
        ],
      ],

      //   应用场景
      appImg: require('@/assets/img/imgYellow/app-1.png'),
      appliList: [
        [
          {
            title: '电商平台/店铺安全',
            desc: '对电商平台中商品进行“色情商品”识别，解决平台在各地区的法律合规问题；平台内部“评论、视频、IM”中涉及到的“色情图”识别；色情商品类目，商品准入判断；平台色情商品打标，差异化分发。',
          },
        ],
        [
          {
            title: '媒体投放合规',
            desc: '规模化社媒投放中，提前对投放品进行“色情商品识别”，解决投放平台商品合规问题。',
          },
        ],
      ],
      // 能力展示
      ability: {
        desc: `模型结果对<img src='${googleLogo}' /> <span style='color:#6C38E0'>google 系列产品（shopping/⾕歌seo等）</span>，进⾏了针对性优化，在此场景下使⽤效果更优。模型⽬前仅⽀持png、jpg、jpeg。`,
      },
      // header请求
      action: `${apiUri}/img/multiSensitive`,
      img_url: '',
      conf: '',
      result: '',
      headers: {
        token: localStorage.getItem('tokenKey')
          ? localStorage.getItem('tokenKey')
          : '',
        isSample: 1,
      },
      // 示例图列表
      exampleDiagram: [
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/rBVapGAj8POAa5HdAAQuS-SVBF4840.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/seqing_002.jpg',
        },
        {
          img: 'https://img4.dhresource.com/webp/m/0x0/f3/albu/ry/g/19/420e2c4c-c375-481c-a4b5-f504c434027d.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/rBNaEmKUyAmAfFESAADlB-oCNLo239.jpg',
        },
        {
          img: 'https://img4.dhresource.com/webp/m/0x0/f3/albu/ry/g/01/005871aa-34da-4cc1-b615-348a5b2b5e4c.jpg',
        },
      ],
      // 初始化选中状态
      imgCurrentActive: 0,
      // 初始化选中图
      resultGraphImg:
        'https://ai-image.s3.cn-north-1.amazonaws.com.cn/aishipgo/test/rBVapGAj8POAa5HdAAQuS-SVBF4840.jpg',
      // 成功图
      successImg: false,
      // 失败图
      errImg: true,
      // 正在加载
      loadImg: true,
      // 加载文案
      errText: '处理中....',
      urlAdress: '',
      // 加载时，禁止点击
      prohibitClicking: false,
      // 优化后按钮
      afterOptimization: false,
      isSample: '',
    };
  },
  computed: {},
  mounted() {
    // 默认点击高亮
    this.selectPicture(this.imgCurrentActive, { img: this.resultGraphImg });
  },
  methods: {
    // 点击选中高亮
    selectPicture(index, { img }) {
      this.resultGraphImg = img;
      this.imgCurrentActive = index;
      this.pornographicRecognition(0, this.resultGraphImg);
    },
    // 色情识别url  请求
    pornographicRecognition(status, resultGraphImg) {
      // if (status == 1) {
      //   this.$message.info('暂不支持自定义上传');
      //   return;
      // }
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';

      if (status == 0) {
        // 点击图片获取地址
        var imgUrl = resultGraphImg;
        // 加载时禁止点击切换
        this.prohibitClicking = true;
        this.isSample = 0;
      } else {
        // 点击检测按钮  获取地址
        var imgUrl = this.urlAdress;
        this.imgCurrentActive = -1;
        this.isSample = 1;
      }

      // 请求接口-------------------
      multiSensitive({
        imgUrl: imgUrl,
        isSample: this.isSample,
      }).then(({ data }) => {
        if (data.state === '0x0000') {
          setTimeout(() => {
            this.urlAdress = '';
            // 成功图
            this.successImg = true;
            this.img_url = data.data.imgUrl;
            this.conf = data.data.conf;
            this.result = data.data.result;

            // 失败图
            this.errImg = false;
            // 正在加载
            this.loadImg = false;
            // 加载完成  可点击切换
            this.prohibitClicking = false;
          }, 1000);
        } else if (data.state === '0x0008') {
          this.$message.error(data.message);
          this.$refs.navheader.logOn();
          // }else if(data.state === "00001"){
        } else {
          setTimeout(() => {
            this.urlAdress = '';
            this.img_url = '';
            this.conf = '';
            this.result = '';
            // 成功图
            this.successImg = false;
            // 失败图
            this.errImg = true;
            this.errText = data.message;
            this.loadImg = false;
            // 加载完成  可点击切换
            this.prohibitClicking = false;
          }, 1000);
        }
      });
    },
    // 图片change操作
    handleChange(file, fileList) {
      // return;
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';
      this.imgCurrentActive = -1;
      // 加载时禁止点击切换
      this.prohibitClicking = true;
    },
    // 图片上传成功后的回调
    getResultImg(response, file, fileList) {
      if (response.state === '0x0000') {
        setTimeout(() => {
          // 成功图
          this.img_url = response.data.imgUrl;
          this.conf = response.data.conf;
          this.result = response.data.result;
          // 成功图
          this.successImg = true;
          // 失败图
          this.errImg = false;
          // 正在加载
          this.loadImg = false;
          // 加载完成  可点击切换
          this.prohibitClicking = false;
        }, 1000);
      } else if (response.state === '0x0008') {
        this.$message.error(response.message);
        this.$refs.navheader.logOn();
      } else {
        setTimeout(() => {
          this.img_url = '';
          this.conf = '';
          this.result = '';
          // 成功图
          this.successImg = false;
          // 失败图
          this.errImg = true;
          this.errText = response.message;
          this.loadImg = false;
          // 加载完成  可点击切换
          this.prohibitClicking = false;
        }, 1000);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/* header */
.core-function,
.application-scene,
.similar-main,
.different-advantage {
  width: 1280px;
  margin: auto;
}
// banner
.header-custom {
  width: 100%;
  margin: auto;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  img {
    position: absolute;
  }
  .banner-bg {
    width: 100%;
    // height: 100%;
    top: 0;
    left: 0;
  }
  .banner1 {
    width: 13.5%;
    bottom: 6%;
    left: 20.6%;
    animation: slideUp 0.7s 1 ease-in-out;
  }
  .banner2 {
    width: 7.5%;
    bottom: 14%;
    animation: slideDown 0.7s 1 ease-in-out;
    left: 34.1%;
  }
  .banner3 {
    width: 16.8%;
    bottom: -6.8%;
    left: 41.6%;
    animation: slideUp 0.7s 1 ease-in-out;
  }
  .banner4 {
    width: 5.83%;
    bottom: 9.2%;
    left: 58.4%;
    animation: slideDown 0.7s 1 ease-in-out;
  }
  .banner5 {
    width: 15.1%;
    bottom: 16.2%;
    right: 20.69%;
    animation: slideUp 0.7s 1 ease-in-out;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.core-function_main,
.application-scene_main,
.different-advantage_main {
  margin-top: 80px;
}
/* 核心 */
.core-function {
  padding: 158px 0 80px;
  .core-custom {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 103.75%;
    margin-left: -1.875%;
    font-family: PingFang SC;
    font-size: 24px;
    color: #fff;
    background: #6c38e0;
    border-radius: 16px;
    padding: 13px 13px 50px 24px;
    margin-top: 131px;
    box-sizing: border-box;
  }
  .core-custon_person {
    position: absolute;
    width: 210px;
    left: 50%;
    top: -77%;
    bottom: auto;
    transform: translateX(-50%);
  }
  .core-custon_text {
    padding-top: 123px;
    flex-shrink: 0;
    margin-right: 23px;
    span {
      display: block;
      font-size: 14px;
      margin-top: 8px;
    }
  }
  .core-custom_diy {
    flex-shrink: 0;
    padding-top: 4.82%;
    margin-right: 12px;
    width: 47.3%;
    img {
      width: 100%;
    }
  }
  .core-custom_IM {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: -262px;
    img {
      width: 100%;
    }
  }

  /deep/ .common-two-column_img {
    width: 32%;
    margin-top: -109px;
  }
  /deep/ .common-two-column_img {
    margin-right: 93px;
  }
  /deep/ .common-two-column {
    column-gap: 133px;
  }
}
/* 差异优势 */
.different-advantage-wrap {
  background: linear-gradient(140deg, #503ad9 20%, #722ab8 145%);
  .different-advantage {
    padding-top: 80px;
    padding-bottom: 49px;
  }
  .diff-top,
  .diff-bottom {
    /deep/ .common-two-column_img {
      width: 50.9%;
      margin-right: 0;
      margin-top: -0;
      overflow: inherit;
    }
  }
  .diff-top {
    /deep/ &.common-two-column {
      column-gap: 104px;
    }
    .diff-list {
      align-items: flex-start;
    }
  }
  .diff-bottom {
    margin-top: -30%;
    /deep/ &.common-two-column {
      column-gap: 24px;
    }
    /deep/ .common-two-column_text {
      margin-top: 16%;
    }
  }
  .diff-list {
    display: flex;
    align-items: center;
    > div {
      img {
        width: 100%;
      }
    }
  }
  .diff-item1 {
    width: 42.6%;
    padding: 24px 24px 147px;
    border-radius: 500px 500px 0 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 47.5%) 0%,
      rgba(255, 255, 255, 0) 95%
    );
  }
  .diff-item2 {
    width: 46.3%;
    padding: 24px;
    border-radius: 0 0 500px 500px;
    margin-left: -24px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 47.5%) 0%,
      rgba(255, 255, 255, 0) 95%
    );
  }
  .diff-item3 {
    width: 29.6%;
    padding: 24px 24px 415px;
    border-radius: 500px 500px 0 0;
    margin-top: -80px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 47.5%) 0%,
      rgba(255, 255, 255, 0) 77%
    );
  }
  .diff-item4 {
    width: 59.4%;
    padding: 118px 24px 24px;
    border-radius: 0 0 500px 500px;
    margin: -206px 0 0 -24px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 47.5%) 0%,
      rgba(255, 255, 255, 0) 95%
    );
  }
}
/* 应用场景 */
.application-scene {
  padding-top: 80px;
  padding-bottom: 120px;
  .application-scene_list {
    position: relative;
    display: flex;
    column-gap: 24px;
    margin-top: -8%;
    padding-bottom: 5%;
    img {
      height: 58px;
    }
  }
  /deep/ .common-two-column_text {
    z-index: 1;
  }
  /deep/ .common-two-column.application-scene_main_bottom {
    column-gap: 432px;
    padding-bottom: 11.5%;
  }

  /deep/ .common-two-column {
    column-gap: 217px;
  }
  /deep/ .common-two-column_img {
    width: 18.9%;
    margin-right: 193px;
    margin-top: 0;
  }
  .application-scene_img {
    position: absolute;
    width: 100%;
    padding-bottom: 26.2%;
    background-image: url('../../assets/img/imgYellow/app-5.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50%;
    margin-top: -22px;
  }
}

.application-scene_main {
  margin-top: 80px;
  position: relative;
  .app-title {
    font-size: 24px;
    color: #000;
    border-radius: 157px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 64px;
      background: #ff8645;
      border-radius: 100px;
    }
  }
  .app-list {
    margin-top: 24px;
    row-gap: 24px;
    li {
      display: flex;
      align-items: center;
      color: #5d5f6c;
      font-size: 16px;
    }
    img {
      width: 22px;
      margin-right: 8px;
    }
  }
}

// 能力展示
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
  /deep/ .common-text {
    img {
      vertical-align: middle;
      display: inline-block;
      margin: -2px 3px 0;
      width: 24px;
    }
  }
  .operationBackgroundColor {
    height: calc(100% - 62px);
  }
  .similarityGraph {
    width: 32.8%;
    background: #d8d8d8;
    border-radius: 8px;
  }
  .operationCenter {
    width: 71.25%;
    height: auto;
    margin-left: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: fit-content;
    }
  }
  .successImgProduct {
    width: 100%;
  }
  .operationYellowRight {
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    margin: 7px 0 0 24px;
  }
  .recognitionResult {
    margin: 0;
    font-size: 18px;
    color: #000108;
    span {
      color: #ff8645;
    }
  }
  .detectionButton {
    background: rgba(108, 56, 224, 0.1);
    color: rgba(108, 56, 224, 1);
  }
  .confidenceLevel {
    margin: 24px 0 0;
    font-size: 14px;
  }
  .similarityGraphMain {
    height: 100%;
    justify-content: space-between;
    row-gap: 0.8%;
    column-gap: 0.8%;
  }
  .similarityGraphImg img {
    border-radius: 8px;
    overflow: hidden;
  }
}
.similar-main-cont {
  // display: flex;
  width: 100%;
  height: 770px;
  margin: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}
@media screen and (max-width: 1280px) {
  .header-custom .bg-left,
  .header-custom .bg-right {
    width: 47.5%;
  }
  .core-function {
    padding-top: 80px;
    .core-function_main {
      margin-top: 60px;
    }
    .core-custom {
      margin-top: 40px;
    }
    .core-custon_text {
      padding-top: 40px;
    }
    .core-custom_diy,
    .core-custom_IM {
      display: none;
    }
    .core-custon_person {
      left: 80%;
      width: 20%;
      top: auto;
      bottom: 5%;
    }
  }
  // 能力展示
  .similar-main-cont {
    height: fit-content;
    .similarityGraphMain {
      justify-content: flex-start;
    }
    .similarityGraph {
      padding-bottom: 25px;
      height: auto;
      img {
        max-width: 90%;
        max-height: 90%;
      }
    }
    .operationRight {
      margin-left: 0;
    }
    .operationCenter {
      margin: 20px 0;
      width: 100%;
      min-height: 600px;
      margin-left: 0;
    }
  }
  .operationBackgroundColor {
    height: auto;
    flex-direction: column;
  }

  .application-scene {
    .application-scene_main {
      margin-top: 80px;
    }
    .app-title {
      font-size: 18px;
      img {
        width: 37px;
      }
    }
    .app-list li {
      font-size: 12px;
      img {
        width: 16px;
      }
    }
    .common-text {
      img {
        width: 14px;
      }
    }
    .application-scene_list {
      margin-top: 20px;
      img {
        height: auto;
        width: 20%;
      }
    }
    .application-scene_img {
      // display: none;
    }
    /deep/ .common-two-column.application-scene_main_bottom {
      margin: 0;
      padding: 0;
      column-gap: 400px;
    }
  }
}
@media screen and (max-width: 990px) {
  .application-scene_img {
    display: none;
  }
  .different-advantage-wrap {
    .diff-bottom {
      margin-top: 30px;
    }
    .diff-bottom {
      /deep/ .common-two-column_text {
        margin-top: 30px;
      }
    }
    .diff-top {
      /deep/ .common-two-column_text {
        margin-top: -180px;
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .banner-imgs {
    position: absolute;
    height: 100%;
    width: 1440px;
    margin: auto;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    bottom: 11px;
  }
  .header-custom .banner-bg {
    height: 880px;
  }
  /deep/.banner-header {
    background: transparent;
  }
}
</style>
